{
  "lang_markread": "Märgi loetuks",
  "lang_newest": "Uued",
  "lang_unread": "Mitteloetud",
  "lang_starred": "Tärniga",
  "lang_tags": "Sildid",
  "lang_alltags": "Kõik sildid",
  "lang_timestamp": "lisatud {0,date} kell {0,time}",
  "lang_hours": "lisatud {0,plural,one {1 tund},other {# tundi}} tagasi",
  "lang_minutes": "lisatud {0,plural,one {1 minut},other {# minutit}} tagasi",
  "lang_seconds": "lisatud {0,plural,one {1 sekund},other {# sekundit}} tagasi",
  "lang_star": "Lisa tärn",
  "lang_unstar": "Eemalda tärn",
  "lang_mark": "Märgi loetuks",
  "lang_unmark": "Märgi mitteloetuks",
  "lang_load_img": "Laadi pildid",
  "lang_open_window": "Ava",
  "lang_searchbutton": "Otsi",
  "lang_sources": "Tellimused",
  "lang_source_add": "Lisa tellimus",
  "lang_source_opml": "või impordi opml-fail või Google Reader",
  "lang_source_export": "ekspordi tellimused",
  "lang_source_edit": "Muuda",
  "lang_source_delete": "Kustuta",
  "lang_source_warn": "Kas tõesti kustutame tellimuse?",
  "lang_source_new": "Uus salvestamata tellimus",
  "lang_source_title": "Nimi",
  "lang_source_tags": "Sildid",
  "lang_source_comma": "komaga eraldatud",
  "lang_source_select": "Tellimuse allika liik",
  "lang_source_type": "Liik",
  "lang_source_save": "Salvesta",
  "lang_source_cancel": "Loobu",
  "lang_source_saved": "Salvestatud",
  "lang_no_entries": "Ühtki sündmust ei leitud",
  "lang_more": "rohkem",
  "lang_login": "Logi sisse",
  "lang_login_username": "Kasutajanimi",
  "lang_login_password": "Salasõna",
  "lang_no_title": "Nimeta",
  "lang_settingsbutton": "Seadistused",
  "lang_filter": "Filtreeri",
  "lang_share_diaspora_label": "Jaga Diaspora vahendusel",
  "lang_logoutbutton": "Logi välja",
  "lang_streamerror": "Ühtegi kirjet pole laaditud, kas laadime uuesti?",
  "lang_share_mastodon_label": "Jaga Mastodoni võrgus",
  "lang_share_wordpress_label": "Jaga WordPressis",
  "lang_share_native_label": "Jaga",
  "lang_offline_navigation_unavailable": "Vallasrežiimis ei saa hetkel silte ja tellimusi vahetada.",
  "lang_source_deleting": "Kustutame tellimuse…",
  "lang_error_edit_source": "Tellimuse muudatuste salvestamine ei õnnestunud:",
  "lang_login_in_progress": "Proovime sisselogida…",
  "lang_error_mark_items": "Kõikide nähtavate kirjete märkimine ei õnnestu:",
  "lang_error_session_expired": "Sessioon on aegunud",
  "lang_error_load_tags": "Siltide laadimine ei õnnestunud:",
  "lang_error_sync": "Viimaste muudatuste sünkroniseerimine serverist ei õnnestu:",
  "lang_error_share_native": "Kirje jagamine ei õnnestu.",
  "lang_close_entry": "Sulge",
  "lang_sources_refreshed": "Tellimus on värskendatud",
  "lang_experimental": "Katselised seadistused",
  "lang_reload_list": "Laadi loend uuesti",
  "lang_error_add_source": "Tellimuse lisamine ei õnnestunud:",
  "lang_share_facebook_label": "Jaga Facebookis",
  "lang_next": "Järgmine",
  "lang_loginbutton": "Logi sisse",
  "lang_share_wallabag_label": "Salvesta Wallabagi",
  "lang_error_offline_storage_not_available": "Vallasrežiimis andmehoidla pole saadaval. Palun kontrolli, et sinu veebibrauser {0}toetab IndexedDB APIt{1} ja kui sa kasutad Google Chrome brauserit, siis sa ei vaata selfoss saiti privaatses režiimis.",
  "lang_error_invalid_subsection": "Vigane alamlõik:",
  "lang_error_configuration": "Seadistuste laadimine ei õnnestu.",
  "lang_info_url_copied": "Aadress on kopeeritud lõikelauale.",
  "lang_article_actions": "Tegevused",
  "lang_article_reading_time": "Lugemiseks kulub {0} minutit",
  "lang_online_count": "Serveris leidub kirjeid",
  "lang_offline_count": "Kohalikus süsteemis leidub kirjeid",
  "lang_searchremove": "Peida otsing",
  "lang_source_filter": "Filtreeri",
  "lang_source_autotitle_hint": "Nime automaatseks tuvastamiseks jäta tühjaks",
  "lang_source_pwd_placeholder": "Pole muudetud",
  "lang_login_invalid_credentials": "Vale kasutajanimi ja/või salasõna",
  "lang_refreshbutton": "Värskenda tellimusi",
  "lang_source_last_post": "Viimane nähtud postitus",
  "lang_source_refresh": "Värskenda see tellimus",
  "lang_login_offline": "Vallasrežiimis andmehoidla",
  "lang_error": "Tekkis viga",
  "lang_days": "lisatud {0,plural,zero {täna},one {eile},other {# päeva tagasi}}",
  "lang_share_mail_label": "Jaga e-kirjaga",
  "lang_share_copy_label": "Kopeeri link lõikelauale",
  "lang_share_pocket_label": "Salvesta Pocketisse",
  "lang_share_twitter_label": "Jaga Twitteris/X'is",
  "lang_search_label": "Otsingusõna",
  "lang_source_menu": "Täiendavad toimingud",
  "lang_source_browse": "Sirvi",
  "lang_source_warn_cancel_dirty": "Kas jätame muudatused salvestamata?",
  "lang_source_params_loading": "Laadime parameetreid…",
  "lang_source_saving": "Salvestame tellimuse muudatusi…",
  "lang_sources_loading": "Laadime tellimustest andmeid…",
  "lang_no_sources": "Ühtegi tellimust pole seadistatud.",
  "lang_source_go_to_settings": "Ava seadistustes",
  "lang_sources_leaving_unsaved_prompt": "Kas soovid lahkuda seadistustest ja jätta tellimuse muudatused salvestamata?",
  "lang_error_delete_source": "Tellimuse kustutamine ei õnnestunud:",
  "lang_error_unknown_source": "Tellimuse teadmata tunnus:",
  "lang_error_refreshing_source": "Tellimuste värskendamine ei õnnestunud:",
  "lang_tag_change_color_button_title": "Muuda värvi",
  "lang_entries_loading": "Laadime kirjeid…",
  "lang_login_error_generic": "Sisselogimine ei õnnestunud: {errorMessage}",
  "lang_error_unauthorized": "Jätkamiseks palun {link_begin}logi sisse{link_end}.",
  "lang_error_check_log_file": "Tekkis viga, palun vaata logifaili „{0}“.",
  "lang_error_check_system_logs": "Tekkis viga, palun vaata süsteemi logifaile.",
  "lang_error_unwritable_logs": "Logide salvestamine ei õnnestu.",
  "lang_app_update": "selfoss on uuendatud, palun laadi uuesti",
  "lang_app_reload": "Laadi uuesti",
  "lang_error_unknown_tag": "Tundmatu silt:",
  "lang_error_mark_item": "Kirje märkimine või märkimise eemaldamine ei õnnestu:",
  "lang_error_star_item": "Kirje tärniga märkimine või märkimise eemaldamine ei õnnestu:",
  "lang_error_logout": "Väljalogimine ei õnnestunud:",
  "lang_error_loading": "Viga laadimisel:",
  "lang_error_loading_stats": "Statistika laadimine ei õnnestu:",
  "lang_error_saving_color": "Uue värvi salvestamine ei õnnestu:",
  "lang_error_offline_storage": "Vallasrežiimis andmehoidla viga: {0}. Uuesti laadimine võib viga parandada. Lülitame vallasrežiimi esialgu välja.",
  "lang_error_share_native_abort": "Kirje jagamine ei õnnestu - kas brauseris pole ühtegi jagamisviisi seadistatud või katkestasid jagamise."
}
